import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `/:companyId${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-wo-company",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "create-project",
    path: `/:companyId/verify/project`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/create-project")
    ),
  },
  {
    key: "create-account",
    path: `/:companyId/verify/createAccount`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/create-account")
    ),
  },
  {
    key: "change-account",
    path: `/:companyId/verify/confirmAccount`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/change-account")
    ),
  },
  {
    key: "initialize-password",
    path: `/:companyId/verify/initPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/verify/initialize-password")
    ),
  },

  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "nbase-projects",
    path: `/:companyId/projects`,
    component: React.lazy(() => import("views/app-views/projects")),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.sales",
    path: `${APP_PREFIX_PATH}/dashboards/sales`,
    component: React.lazy(() => import("views/app-views/dashboards/sales")),
  },
  {
    key: "analytics.user",
    path: `${APP_PREFIX_PATH}/analytics/user`,
    component: React.lazy(() => import("views/app-views/analytics/user")),
  },
  {
    key: "analytics.sales",
    path: `${APP_PREFIX_PATH}/analytics/sales`,
    component: React.lazy(() => import("views/app-views/analytics/sales")),
  },
  {
    key: "operation.user.list",
    path: `${APP_PREFIX_PATH}/operation/user/list`,
    component: React.lazy(() => import("views/app-views/operation/user/list")),
  },
  {
    key: "operation.user.dau",
    path: `${APP_PREFIX_PATH}/operation/user/dau`,
    component: React.lazy(() => import("views/app-views/operation/user/dau")),
  },
  {
    key: "operation.user.nru",
    path: `${APP_PREFIX_PATH}/operation/user/nru`,
    component: React.lazy(() => import("views/app-views/operation/user/nru")),
  },
  {
    key: "operation.user.ccu",
    path: `${APP_PREFIX_PATH}/operation/user/ccu`,
    component: React.lazy(() => import("views/app-views/operation/user/ccu")),
  },
  {
    key: "operation.user.suspended",
    path: `${APP_PREFIX_PATH}/operation/user/suspended`,
    component: React.lazy(() =>
      import("views/app-views/operation/user/suspended")
    ),
  },
  {
    key: "operation.purchase.list",
    path: `${APP_PREFIX_PATH}/operation/purchase/list`,
    component: React.lazy(() =>
      import("views/app-views/operation/purchase/list")
    ),
  },
  {
    key: "operation.purchase.in_app_purchase",
    path: `${APP_PREFIX_PATH}/operation/purchase/in_app_purchase`,
    component: React.lazy(() =>
      import("views/app-views/operation/purchase/in_app_purchase")
    ),
  },
  {
    key: "operation.purchase.statistics",
    path: `${APP_PREFIX_PATH}/operation/purchase/statistics`,
    component: React.lazy(() =>
      import("views/app-views/operation/purchase/statistics")
    ),
  },
  {
    key: "operation.purchase.canceled_payment",
    path: `${APP_PREFIX_PATH}/operation/purchase/canceled_payment`,
    component: React.lazy(() =>
      import("views/app-views/operation/purchase/canceled_payment")
    ),
  },
  {
    key: "operation.purchase.external_payment",
    path: `${APP_PREFIX_PATH}/operation/purchase/external_payment`,
    component: React.lazy(() =>
      import("views/app-views/operation/purchase/external_payment")
    ),
  },
  {
    key: "operation.maintenance_and_update.maintenance",
    path: `${APP_PREFIX_PATH}/operation/maintenance_and_update/maintenance`,
    component: React.lazy(() =>
      import("views/app-views/operation/maintenance_and_update/maintenance")
    ),
  },
  {
    key: "operation.maintenance_and_update.update",
    path: `${APP_PREFIX_PATH}/operation/maintenance_and_update/update`,
    component: React.lazy(() =>
      import("views/app-views/operation/maintenance_and_update/update")
    ),
  },
  {
    key: "operation.message.push_notification",
    path: `${APP_PREFIX_PATH}/operation/message/push_notification`,
    component: React.lazy(() =>
      import("views/app-views/operation/message/push_notification")
    ),
  },
  {
    key: "operation.message.text_message",
    path: `${APP_PREFIX_PATH}/operation/message/text_message`,
    component: React.lazy(() =>
      import("views/app-views/operation/message/text_message")
    ),
  },
  {
    key: "operation.coupon.general_coupon",
    path: `${APP_PREFIX_PATH}/operation/coupon/general_coupon`,
    component: React.lazy(() =>
      import("views/app-views/operation/coupon/general")
    ),
  },
  {
    key: "operation.coupon.keyword_coupon",
    path: `${APP_PREFIX_PATH}/operation/coupon/keyword_coupon`,
    component: React.lazy(() =>
      import("views/app-views/operation/coupon/keyword")
    ),
  },
  {
    key: "operation.coupon.coupon_usage",
    path: `${APP_PREFIX_PATH}/operation/coupon/coupon_usage`,
    component: React.lazy(() =>
      import("views/app-views/operation/coupon/usage")
    ),
  },
  {
    key: "operation.customer_support.dashboard",
    path: `${APP_PREFIX_PATH}/operation/customer_support/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/dashboard")
    ),
  },
  {
    key: "operation.customer_support.customer_inquiry",
    path: `${APP_PREFIX_PATH}/operation/customer_support/customer_inquiry/*`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/customer_inquiry")
    ),
  },
  {
    key: "operation.customer_support.statistics",
    path: `${APP_PREFIX_PATH}/operation/customer_support/statistics`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/statistics")
    ),
  },
  {
    key: "operation.customer_support.reviews",
    path: `${APP_PREFIX_PATH}/operation/customer_support/reviews`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/reviews")
    ),
  },
  {
    key: "operation.customer_support.custom_page",
    path: `${APP_PREFIX_PATH}/operation/customer_support/custom_page`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/custom_page")
    ),
  },
  {
    key: "operation.customer_support.settings",
    path: `${APP_PREFIX_PATH}/operation/customer_support/settings/*`,
    component: React.lazy(() =>
      import("views/app-views/operation/customer_support/settings")
    ),
  },
  {
    key: "operation.game.ranking",
    path: `${APP_PREFIX_PATH}/operation/game/ranking`,
    component: React.lazy(() =>
      import("views/app-views/operation/game/ranking")
    ),
  },
  {
    key: "operation.game.friendship",
    path: `${APP_PREFIX_PATH}/operation/game/friendship`,
    component: React.lazy(() =>
      import("views/app-views/operation/game/friendship")
    ),
  },
  {
    key: "operation.game.player",
    path: `${APP_PREFIX_PATH}/operation/game/player`,
    component: React.lazy(() =>
      import("views/app-views/operation/game/player")
    ),
  },
  {
    key: "operation.game.gift",
    path: `${APP_PREFIX_PATH}/operation/game/gift`,
    component: React.lazy(() => import("views/app-views/operation/game/gift")),
  },
  {
    key: "operation.game.item",
    path: `${APP_PREFIX_PATH}/operation/game/item`,
    component: React.lazy(() => import("views/app-views/operation/game/item")),
  },
  {
    key: "operation.game.notice",
    path: `${APP_PREFIX_PATH}/operation/game/notice`,
    component: React.lazy(() =>
      import("views/app-views/operation/game/notice")
    ),
  },
  {
    key: "operation.game_operation.data_store",
    path: `${APP_PREFIX_PATH}/operation/game_operation/data_store`,
    component: React.lazy(() =>
      import("views/app-views/operation/game_operation/data_store")
    ),
  },
  {
    key: "operation.game_operation.remote_config",
    path: `${APP_PREFIX_PATH}/operation/game_operation/remote_config`,
    component: React.lazy(() =>
      import("views/app-views/operation/game_operation/remote_config")
    ),
  },
  {
    key: "operation.game_operation.game_log",
    path: `${APP_PREFIX_PATH}/operation/game_operation/game_log`,
    component: React.lazy(() =>
      import("views/app-views/operation/game_operation/game_log")
    ),
  },
  {
    key: "operation.game_operation.data_security",
    path: `${APP_PREFIX_PATH}/operation/game_operation/security`,
    component: React.lazy(() =>
      import("views/app-views/operation/game_operation/security")
    ),
  },
  {
    key: "operation.game_operation.blacklist",
    path: `${APP_PREFIX_PATH}/operation/game_operation/blacklist`,
    component: React.lazy(() =>
      import("views/app-views/operation/game_operation/blacklist")
    ),
  },
  {
    key: "operation.chat",
    path: `${APP_PREFIX_PATH}/operation/chat/*`,
    component: React.lazy(() => import("views/app-views/operation/chat")),
  },
  {
    key: "setting.project_settings.general",
    path: `${APP_PREFIX_PATH}/setting/project_settings/general`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/general")
    ),
  },
  {
    key: "setting.project_settings.authentication",
    path: `${APP_PREFIX_PATH}/setting/project_settings/authentication`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/authentication")
    ),
  },
  {
    key: "setting.project_settings.store",
    path: `${APP_PREFIX_PATH}/setting/project_settings/store`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/store")
    ),
  },
  {
    key: "setting.project_settings.integration",
    path: `${APP_PREFIX_PATH}/setting/project_settings/integration`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/integration")
    ),
  },
  {
    key: "setting.project_settings.payment",
    path: `${APP_PREFIX_PATH}/setting/project_settings/payment`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/payment")
    ),
  },
  {
    key: "setting.project_settings.api_key",
    path: `${APP_PREFIX_PATH}/setting/project_settings/api_key`,
    component: React.lazy(() =>
      import("views/app-views/setting/project_settings/api_key")
    ),
  },
  {
    key: "setting.profile_settings",
    path: `${APP_PREFIX_PATH}/setting/profile_settings`,
    component: React.lazy(() =>
      import("views/app-views/setting/profile_settings")
    ),
  },
  {
    key: "setting.admin.list",
    path: `${APP_PREFIX_PATH}/setting/admin/list`,
    component: React.lazy(() => import("views/app-views/setting/admin/list")),
  },
  {
    key: "setting.admin.export",
    path: `${APP_PREFIX_PATH}/setting/admin/export`,
    component: React.lazy(() => import("views/app-views/setting/admin/export")),
  },
  {
    key: "setting.admin.authority",
    path: `${APP_PREFIX_PATH}/setting/admin/authority`,
    component: React.lazy(() =>
      import("views/app-views/setting/admin/authority")
    ),
  },
  {
    key: "setting.admin.admin_log",
    path: `${APP_PREFIX_PATH}/setting/admin/admin_log`,
    component: React.lazy(() =>
      import("views/app-views/setting/admin/admin_log")
    ),
  },
  {
    key: "download.sdk_download.android",
    path: `${APP_PREFIX_PATH}/download/sdk_download/android`,
    component: React.lazy(() =>
      import("views/app-views/download/sdk_download/android")
    ),
  },
  {
    key: "download.sdk_download.ios",
    path: `${APP_PREFIX_PATH}/download/sdk_download/ios`,
    component: React.lazy(() =>
      import("views/app-views/download/sdk_download/ios")
    ),
  },
  {
    key: "download.sdk_download.unity",
    path: `${APP_PREFIX_PATH}/download/sdk_download/unity`,
    component: React.lazy(() =>
      import("views/app-views/download/sdk_download/unity")
    ),
  },
  {
    key: "download.sdk_download.unreal",
    path: `${APP_PREFIX_PATH}/download/sdk_download/unreal`,
    component: React.lazy(() =>
      import("views/app-views/download/sdk_download/unreal")
    ),
  },
  {
    key: "error",
    path: `/error`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];
